<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.interactive.title') }}
      </div>
      <el-select
        size="mini"
        v-model="localValue.type"
        clearable
      >
        <el-option
          v-for="item in Object.keys($t('process_editor.element_properties.user_task.interactive.types'))"
          :key="item"
          :label="$t(`process_editor.element_properties.user_task.interactive.types.${item}`)"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <open-card
      v-if="localValue.type === 'open_card'"
      :field-registry-id="parseInt(extensions.task_registry_id) || null"
      v-model="localValue.open_card"
    />
  </div>
</template>

<script>
import OpenCard
  from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/Shared/InteractiveEditor/OpenCard.vue'
import { parseDotNotations, prepareDotNotations } from '@/services/BPMNEditor/infrastructure/utils/utils'

export default {
  name: 'InteractiveEditor',
  components: { OpenCard },
  props: {
    extensions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      localValue: this.parseObject(this.extensions)
    }
  },
  watch: {
    localValue: {
      handler () {
        this.$emit('remove-extension', { filter: (_) => _.indexOf('interactive.') === 0 })
        const result = prepareDotNotations(this.localValue)
        for (let key in result) {
          if (result[key]) {
            this.$emit('change-extension', { name: 'interactive.' + key, value: result[key] })
          }
        }
      },
      deep: true
    }
  },
  methods: {
    parseObject (object) {
      const interactiveSettings = Object.keys(object).filter(_ => _.indexOf('interactive.') === 0).reduce((obj, key) => {
        obj[key] = object[key]
        return obj
      }, {})
      return parseDotNotations(interactiveSettings).interactive || {}
    }
  }
}
</script>
